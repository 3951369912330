.small-screen-nav {
    position: absolute;
    z-index: 110;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.325s ease-in-out, opacity 0.225s ease-in;
    background-color: var(--primary-color-1);
    text-align: center;
    padding-top: 1.5rem;

    ul {
        list-style: none;
        padding: 0;
        margin: 1rem 0;

        > li {
            margin-bottom: 1.5rem;
        }
    }

    a {
        color: #fff;
    }
}

.small-screen-nav--active {
    transform: none;
    opacity: 1;
    transition: transform 0.45s ease-out, opacity 0.225s ease-in 0.1s;
}

.small-screen-nav__close {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 3.8rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
}

.find-us-link.find-us-link--mobile {
    float: none;
    font-size: 1.2rem;
    font-weight: 500;
}

.small-screen-nav__ion-mystery {
    width: 126px;
    height: 54px;
    display: block;
    margin: 0 auto;
    background-image: url(https://ewscripps.brightspotcdn.com/89/0e/b7c9f11b4fed91bbbb0b0c3c69e2/ion-mystery-logo.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.small-screen-nav__social {
    font-size: 1.4rem;

    > a {
        margin-right: 1.5rem;
    }
}

.small-screen-nav__seperator {
    margin: 1.5rem auto;
    width: 66%;
    border: 1px solid #fff;
    border-width: 1px 0 0;
}

.small-screen-nav__related-sites {
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
}

.related-sites__link {
    display: block;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

.related-sites--ion {
    background-image: url(https://ewscripps-brightspot-lower.s3.amazonaws.com/ae/b4/ccc9868e43069468e56c9285a43c/iontv-logo-white.png);
    background-position: center;
    width: 85px;
    height: 43px;
}

.related-sites--ion-plus {
    background-image: url(https://ewscripps.brightspotcdn.com/49/4d/5a318d674693acf269a7cad94081/button-ion-plus-logo-2x.png);
    background-position: bottom;
    width: 89px;
}

