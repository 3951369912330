.social-link--instagram {
    .social-link__icon {
        stroke: #fff;
        fill: none;
    }

    &:hover {
        .social-link__icon {
            fill: none;
            stroke: #fff;
        }
    }
}

.social-link--youtube {
    .social-link__icon {
        polygon {
            fill: var(--primary-color-1); ;
        }
    }
}

.social-link--twitter {
    .social-link__icon {
        fill: #fff;
    }
}

.social-links-item {
    display: flex;
    gap: 1rem;
    align-items: center;

    > span {
        color: #fff;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}