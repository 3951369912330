.affiliates-list {
    border-bottom: 1px solid var(--primary-color-3);
}

.affiliates-list__row {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    font-weight: 400;
}

.affiliates-list__item,
.affiliates-list__header {
    flex: 1 1 20%;
}

.affiliates-list__title-row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 2px auto;
}

.affiliates-list__title {
    text-align: center;
    width: 100%;
    background-color: var(--primary-color-4);
    padding: 0.75rem 0;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: var(--primary-color-2);
    margin: 0;
}

.affiliates-list__toggle-btn {
    margin-right: 1.6rem;
    align-self: center;
}

.affiliates-list__mobile-label {
    display: none;
}

.affiliates-list__item,
.affiliates-list__header {
    padding: 0.6rem 1.4rem;
    border-right: 1px solid var(--primary-color-3);
}

.affiliates-list__item:last-child,
.affiliates-list__header:last-child {
    border-right: none;
}

.affiliates-list__row:nth-child(even) {
    background-color: rgba(160, 160, 160, 0.1);
}

.affiliates-list__row.affiliates-list__header-row {
    background-color: var(--primary-color-1);
    color: var(--primary-color-2);
}

.affiliates-list__header {
    font-weight: 700;
    text-transform: uppercase;
    border-color: var(--primary-color-2);
}

.affiliates-list__city {
    flex-basis: 25%;
}

.affiliates-list__cable {
    flex-basis: 33%;
}

.affiliates-list__no-results {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .affiliates-list__item {
        border-right: none;
    }

    .affiliates-list__item {
        padding: 0.4rem 0.8rem;
    }

    .affiliates-list__mobile-label {
        display: inline-block;
        flex: 0 0 4em;
        font-weight: 700;
        color: var(--primary-color-4);
        padding-right: 0.5rem;
    }

    .affiliates-list__header-row {
        display: none;
    }

    .affiliates-list__row {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.7rem 0;
        border-top: 1px dotted;
    }

    .affiliates-list__item {
        display: flex;
        font-size: 0.9rem;
    }

    .affiliates-list__title {
        font-size: 1.2em;
        padding-left: 0.8rem;
    }

    .affiliates-list__toggle-btn {
        margin-right: 0.8rem;
    }

    .affiliates-list__city {
        font-size: 1.1em;
        font-weight: 700;
    }

    .affiliates-list__title-row {
        margin-bottom: 0;
    }

    .affiliates-list__hide-on-mobile {
        display: none;
    }
}