/* Improved .sr-only (https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034) */
.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; 
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            
}

.no-scroll {
	overflow: hidden;
}

.reset-list {
	list-style: none;
	padding: 0;
	margin: 0;
}