.main-header {
    --nav-color: var(--primary-color-1);
}

.networks-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: baseline;
}

.networks-nav__item {
    flex: 0 1 6rem;
    height: 5rem;
}

.networks-nav__item--ion-mystery {
    flex-basis: 9rem;
}

.networks-nav__item--ion-plus {
    flex-basis: 7rem;
}

.networks-nav__link {
    position: relative;
    z-index: 1;
    width: inherit;
    height: inherit;
    display: block;
    padding: 0;
    transition: opacity 0.325s ease-out;

    background-repeat: no-repeat;
    background-position: center;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        border: 2px solid #fff;
        border-width: 0 2px 0 0;
        transition: opacity 0.325s ease-out;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.networks-nav--ion-mystery {
    background-color: var(--primary-color-1);
    background-image: url(https://ewscripps.brightspotcdn.com/89/0e/b7c9f11b4fed91bbbb0b0c3c69e2/ion-mystery-logo.svg);
    background-size: auto 45%;
    height: inherit;

    &.inactive {
        &:after {
            content: '';
            background-image:  url(https://ewscripps-brightspot-lower.s3.amazonaws.com/af/5b/b33a26334e48b73c73b7e330d6ed/ion-mystery-logo-gray.svg);
            background-repeat: no-repeat;
            background-size: 58% auto;
            background-color:#fff;
            background-position: center;
            height: inherit;
            opacity: 1;
        }
    }
}

.networks-nav--ion-plus {
    background-image: url(https://ewscripps.brightspotcdn.com/21/3a/422d721c42e5bf75613b2c6592df/ion-plus-logo-grey.png);
    background-size: auto 41.7%;
    background-position: top 1.5rem center;
    height: 100%;

    &:after {
        content: '';
        background-image: url(https://ewscripps.brightspotcdn.com/49/4d/5a318d674693acf269a7cad94081/button-ion-plus-logo-2x.png), linear-gradient(to bottom, hsl(26, 87%, 55%) 0%, hsl(13, 87%, 54%));
        background-repeat: no-repeat;
        background-size: auto 41.7%, 100% auto;
        background-position: top 1.5rem center, top left;
        background-color: var(--primary-color-1);
        height: calc(100% - 2px);
        border-width: 0 0 0 2px;
    }
}

.networks-nav--ion {
    background-image: url(https://ewscripps-brightspot-lower.s3.amazonaws.com/4a/53/5c100eb64975865c44b579a5fa97/iontv-logo-grey.png);
    background-size: auto 90%;
    height: 100%;

    &:after {
        content: '';
        background-image: url(https://ewscripps-brightspot-lower.s3.amazonaws.com/ae/b4/ccc9868e43069468e56c9285a43c/iontv-logo-white.png), linear-gradient(to bottom, hsl(22, 100%, 57%) 0%, hsl(18, 100%, 49%) 100%);
        background-repeat: no-repeat;
        background-position: calc(50% + 1px) calc(50% + 1px);
        background-size: auto 93.5%, 100% auto;
        background-color: var(--primary-color-1);
        height: calc(100% - 2px);
    }
}

.nav-color-bar {
    height: 1.2rem;
    padding: 0.4rem 0 0.1rem;
    background-color: var(--nav-color);
    transition: background-color 0.325s ease-out;
    position: relative;
    z-index: 5;
}

.find-us-link {
    color: #fff;
    text-decoration: none;
    float: right;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;

    svg {
        vertical-align: middle;
    }
}

