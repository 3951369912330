.schedule-list__timezone {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.5rem 0;
}

.schedule-list {
    margin: 1rem 0 0;
    padding: 0 0 2rem 0;
    list-style: none;
}

.schedule-list__item {
    border-bottom: 1px solid var(--primary-color-3);
    margin-bottom: 1rem;
}

.schedule-item__time {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.schedule-item__program {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.schedule-item__title {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.schedule-item__synopsis {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.schedule-item__cast {
    font-size: 0.8rem;
    font-style: italic;
    margin-bottom: 1rem;
}

.schedule-item__rating {
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

