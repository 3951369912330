.mobile-nav {
    display: none;
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
}

.mobile-nav__item {
   
    &:last-child {
        margin: 0 1.5rem 0 auto;
    }
}

.mobile-nav__activate-btn {
    background-color: transparent;
    border: none;
    font-size: 1.6rem;
    margin-top: 0.5rem;
    cursor: pointer;
    color: #000;
}

.mobile-nav__logo {
    display: block;
    background-image: url(https://iontelevision.com/static/global/corp-logos/ION-Mystery_Logo-Orange-Black.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 108px;
    padding-top: 44px;
    margin-left: 1.5rem;
}

@media screen and (max-width: 600px) {
    .mobile-nav {
        display: flex;
        align-items: center;
    }

    .networks-nav {
        display: none;
    }
}