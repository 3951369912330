.svg-map {
    width: 100%;
    height: auto;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round; 
}

.svg-map__location {
    cursor: pointer; 
    transition: fill 0.325s ease-in-out;
}

.svg-map__location:focus, 
.svg-map__location:hover {
    outline: 0; 
}

.svg-map {
    stroke: rgb(216, 216, 216);
}

.svg-map__location {
    fill: var(--primary-color-1);
}

.svg-map__location:focus, 
.svg-map__location:hover {
    fill: var(--primary-color-3);
}

.svg-map__location[aria-checked="true"] {
    fill: var(--primary-color-4)
}
