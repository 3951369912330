.schedule-nav {
    padding: 0.5rem;
    background-image: url(https://ewscripps.brightspotcdn.com/d8/88/60459e4f46ea8aa31478a5f0c76b/escape-bg-investigation-crimescenetape.png);
    background-size: 100% auto;
    background-position: center;
    position: sticky;
    top: 0;
}

.schedule-nav__list {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    background: rgba(0,0,0, 0.6);
    list-style: none;
}

.schedule-nav__day {
    button { 
        color: var(--primary-color-2);
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        transition: border-color 0.325s ease-out;

        &.active {
            border-color: var(--primary-color-1);
        }

        &:hover {
            border-color: var(--primary-color-3);
        }
    }

    .day__abbr {
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 850px) {
    .schedule-nav .constrain-content {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .schedule-nav .constrain-content {
        padding: 0;
    }

    .schedule-nav__list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .schedule-nav__list {
        width: 100%;
        padding: 0.5rem 0;

    }

    .schedule-nav__day {
        flex: 0 1 12.5%;


        button {
            font-size: 0.9rem;
        }

        &:last-child {
            display: none;
        }
    }
}

@media screen and (max-width: 350px) {
    .schedule-nav__list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .schedule-nav__day {
        margin-bottom: 0.75rem;
    }
}