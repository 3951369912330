.main-footer {
    background-color: var(--primary-color-1);
    padding: 1.5rem 0;
}

.main-footer > .constrain-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-nav__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0.5rem;
    padding: 0;
}

.footer-nav__item {
    width: fit-content;
    flex: 0 1 fit-content;
    padding: 0 0.75rem;
    margin-bottom: 0.5rem;
    border-right: 1px solid var(--primary-color-2);
    line-height: 0.8;

    &:last-child {
       border-right: none;
    }
}

.footer-nav__link {
    color: var(--primary-color-2);
    font-size: 0.7rem;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.225s transform ease-out;
    display: inline-block;

    &:hover {
        transform: scale(1.05);
     
    }
}

.footer-nav__social-link {
    font-size: 1.4rem;
    > svg {
        fill: var(--primary-color-2);
    }
}

.copyright {
    font-size: 0.65rem;
    font-weight: 400;
    text-align: center;
    padding: 0 1rem;
    color: var(--primary-color-2);
}