@font-face {
    font-family: 'SourceSansPro';
    font-weight: 400;
    src: url('../../fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
        url('../../fonts/sourcesanspro-regular-webfont.woff') format('woff');
  }
  
  @font-face {
    font-family: 'SourceSansPro';
    font-weight: 600;
    src: url('../../fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'),
        url('../../fonts/sourcesanspro-semibold-webfont.woff') format('woff');
  }
  
  @font-face {
    font-family: 'SourceSansPro';
    font-weight: 700;
    src: url('../../fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
        url('../../fonts/sourcesanspro-bold-webfont.woff') format('woff');
  }
  
  ad-block {
      --_tablonavy: #131e3a;
      --_orange: #ff5122;
      --_coolgray: #d9d8d6;
      --animation-delay: 100ms;
      --animation-duration: 450ms;
      --animation-timing-function: cubic-bezier(1.000, 0.000, 0.490, 1.000);
      --animation-fill-mode: backwards;  
  }
  
  .ad-anchor {
    display: flex;
    position: relative;
    align-items: center;
    max-width: 768px;
    margin: 0 auto;
    text-decoration: none;     
    z-index: 1;
    cursor: pointer;
    font-family: 'SourceSansPro';
    
    &:hover .ad-copy__link,
    &:focus-within .ad-copy__link {
      transform: scale(1.05);
    }
  }
  
  .background-container {
    background-image: url("https://cdn.scrippscloud.com/web/images/tablo/ads/JesseFantasyAd_768x90.jpg");
    background-position: center;
    width: 100%;
    display: grid;
    height: 90px;
    /* padding-bottom: 11.72%; */
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 3.5fr 2.5fr 2fr;
  }
  
  .ad-copy {
    margin-left: 1em;
    text-align: left;
  }
  
  .ad-copy__header {
    color: var(--_tablonavy);
    font-size: 1.75em;
    margin: 0;
    margin-left: 0;
    font-weight: 700;
    line-height: 0.9;
    width: 90%;
  }
  
  .ad-copy__description {
    color: var(--_tablonavy);
    font-family: 'SourceSansPro';
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.2;
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0;
  }
  
  .cta-container {
    text-align: left;
    align-self: end;
    span {
      display: none;
    }
  }
  
  .cta-container__link {
    background-color: var(--_orange);
    color: white;
    font-weight: 700;
    border-radius: 24px;
    padding: 3px 11px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.9em;
    transition: transform 0.225s ease-in-out;
    white-space: nowrap;
    margin-bottom: 0.6em;
    margin-left: 0.5em;
    line-height: 1.1;
  
    &:hover {
        transform: scale(1.05);
    }
  }
  
  .ad-image {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0.75em;
    padding-bottom: 0.25em;
  }

  ad-image {
    display: block;
    width: min(55%, 150px);
    padding-bottom: 0.25em;
    
    > a {
        display: inline-block;
        transition: transform 0.225s ease-in-out;

        &:hover {
            transform: scale(1.05);
        }
    }

    img {
        width: 100%;
        max-height: 6rem;

        @media screen and (max-width: 37.5rem) {
            max-height: 9rem;
        }
    }
  }
  
  .ad-image__text {
    color: var(--_coolgray);
    font-size: 6px;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    width: 80%;
  }

  @media screen and (max-width: 930px) {
    .ad-copy__header {
      font-size: 1.35em;
    }

    .ad-copy__description {
      font-size: 0.65em;
    }
  }
  
  @media screen and (min-width: 801px) {
    .ad-image-mobile {
      display: none;
    }
  }
  
  @media screen and (max-width: 800px) { 
    .ad-anchor {
      max-width: 320px;
      margin: 0 auto;
    }
  
    .background-container {
      background-image: url("https://cdn.scrippscloud.com/web/images/tablo/ads/JesseFantasyAd_320x280.jpg");
      width: 100%;
      height: 280px;
      display: block;
      text-align: left;
    }
  
    .ad-image-mobile {
      display: block;
  
      ad-image {
        width: auto;
        padding-top: 0.8em;
        padding-bottom: 0.25em;
        display: block;
  
        a {
          width: 55%;
          display: inline-block;
        }
      }
    }
  
    ad-image {
      padding-bottom: 0;
    }
  
    .ad-image ad-image {
      display: none;
    }
  
    .ad-copy {
      width: 60%;
      margin-top: 1.5em;
      margin-left: 1.5em;
    }
  
    .ad-copy__header {
      font-size: clamp(1.1em, 5.7vw, 1.5em);
      line-height: 1;
      width: 100%;
    }
  
    .ad-copy__description {
      font-size: clamp(0.65em, 3.8vw, 0.8em);
      line-height: 1.1;
      width: 26ch;
      margin-top: 0;
    }
  
    .cta-container {
      margin-left: 1.5em;
      width: 60%;
      position: absolute;
      bottom: 12px;
  
      span {
        display: block;
        font-size: 6px;
        line-height: 1.1;
        text-align: left;
      }
    }
  
    .cta-container__link {
      font-size: 1em;
      margin-left: 0;
      margin-bottom: 0.7em;
    }
  
    .ad-image {
      margin-left: 1.25em;
      width: 48%;
    }
    
    .ad-image__text {
      display: none;
    }
  }
  
  @media screen and (max-width: 360px) {
    .background-container {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 0;
      padding-bottom: calc(280 / 320 * 100%);
    }
  
    .ad-copy {
      margin-top: 1.2em;
      margin-left: 1.2em;
    }
  
    .cta-container {
      margin-left: 1.2em;
    }
  
    .cta-container__link {
      font-size: 0.9em;
      margin-bottom: 0.5em;
    }
  
    .cta-container {
      bottom: 10px;
    }
  
    .cta-container span {
      font-size: 5px
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .ad-image--animate {
        animation-name: promo-slide-in-right;
        animation-duration: var(--animation-duration);
        animation-delay: var(--animation-delay);
        animation-fill-mode: var(--animation-fill-mode);
        animation-timing-function: var(--animation-timing-function);
    }
  
    .ad-copy--animate {
        animation-name: promo-slide-in-left;
        animation-duration: var(--animation-duration);
        animation-delay: var(--animation-delay);
        animation-fill-mode: var(--animation-fill-mode);
        animation-timing-function: var(--animation-timing-function);
    }
  
    .ad-cta--animate {
      animation-name: promo-slide-in-left;
      animation-duration: var(--animation-duration);
      animation-delay: var(--animation-delay);
      animation-fill-mode: var(--animation-fill-mode);
      animation-timing-function: var(--animation-timing-function);
  
      @media screen and (max-width: 800px) { 
        animation-name: promo-slide-in-right;
      }
    }
  
    .ad-mobile-disclaimer--animate {
      animation-name: promo-slide-in-right;
      animation-duration: var(--animation-duration);
      animation-delay: var(--animation-delay);
      animation-fill-mode: var(--animation-fill-mode);
      animation-timing-function: var(--animation-timing-function);
    }
  }
  
  @keyframes promo-slide-in-left {
    from { 
        transform: translateX(-10em);
        opacity: 0;
    }
    to { 
        transform: none;
        opacity: 1; 
    }
  }
  
  @keyframes promo-slide-in-right {
    from { 
        transform: translateX(10em);
        opacity: 0;
    }
    to { 
        transform: none;
        opacity: 1; 
    }
  }
  