.overlay {
    position: fixed;
    top: 0;    
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0, 0.6);
    opacity: 0;
    visibility: hidden;
}

.overlay--active {
    opacity: 1;
    transition: opacity 0.225s ease-out;
    visibility: visible;
}

