.social-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin-block-end: 1rem;
    border: 1px solid #fff;
    transition: scale 0.255s ease-in-out, border-radius 0.225s ease-out;

    &:first-of-type {
        padding: 0.5rem 0.6rem 0.5rem 0.4rem;
    }

    &:hover {
        scale: 1.1;

        .social-link__icon { 
            fill: #fff; 
            stroke: none;
        }
    }

    svg {
        stroke: none;
    }

    i {
        display: inline-flex;
    }

    .social-link__icon {
        transition: fill 0.225s ease-in-out;
        fill: #fff;
        stroke: transparent;
        stroke-width: 1px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.social-links { 
    display: flex;
    gap: 0.75rem;
    align-items: center;
    padding-top: 1rem;
 }