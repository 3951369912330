:root {
    // primary colors
    --primary-color-1: rgb(255, 119, 51);
    --primary-color-2: #fff;
    --primary-color-3: rgb(160, 160, 160);
    --primary-color-4: #555;

    --ionplus-color: #5AC03E;
}

html {
    --base-font-size: 16px;
    --sm-screen-font-size: 14px;
}