.viewing-platforms.constrain-content {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--primary-color-3);
}

.viewing-platforms__header {
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
}

.viewing-platforms__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

.viewing-platforms__item {
    flex: 0 1 calc(20% - 2.4rem);
    align-items: center;
    justify-content: center;
    padding: 0 0 2rem 0;
    margin-right: 3rem;
    box-sizing: border-box;

    &:nth-child(5n) {
        margin-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 960px) {
    .viewing-platforms__item {
        flex: 0 1 calc(25% - 2.4rem);

        &:nth-child(5n) {
            margin-right: 3rem;
        }
    
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .viewing-platforms__item {
        flex: 0 1 calc(33% - 2.4rem);

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:nth-child(4n) {
            margin-right: 3rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .viewing-platforms__item {
        flex: 0 1 calc(50% - 2.4rem);

        &:nth-child(3n) {
            margin-right: 3rem;
        }

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}

.viewing-platforms__link {
    display: block;
    transition: transform 0.225s ease-in-out;
    
    &:hover {
        transform: scale(1.05);
    }
}

.viewing-platforms__logo {
    width: 100%;
}