@charset "UTF-8";
@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html {
    font-size: var(--base-font-size);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 300;
}

button {
    font-family: 'Open Sans';
    font-weight: 400;
}

/* normalize override (instead of "bolder") */
:where(b,strong) {
    font-weight: bold;
}

.content {
    padding-bottom: 3rem;
}

.constrain-content {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
}

.section-header {
    color: var(--primary-color-2);
    padding: 1.25rem 0;
    font-size: 1.8rem; 
    text-transform: uppercase;
    text-align: center;
    background-image: url(https://ewscripps.brightspotcdn.com/d8/88/60459e4f46ea8aa31478a5f0c76b/escape-bg-investigation-crimescenetape.png);
    background-size: 100% auto;
    background-position: bottom center;
    text-shadow: 1px 1px 1px rgba(0,0,0, 1), 1px 1px 2px rgba(0,0,0, 0.8);
}

.content-wrapper--blur {
    filter: blur(2px);
}

@media screen and (max-width: 600px) {
    html {
        font-size: var(--sm-screen-font-size);
    }

    .constrain-content {
        width: 100%;
        margin: 0;
        padding: 0 1.5rem;
        box-sizing: border-box;
    }
}

@import 'Utilities';