.states-list__list {
    list-style: none;
    columns: 2;
}

.states-list__mobile-list {
    display: none;
}

.states-list__selected {
    font-weight: 700;
}

.states-list__link {
    transition: transform 0.125s ease-in-out;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0.2rem 0;
    transform-origin: left center;
}

.states-list__link:hover,
.states-list__link:focus {
    transform: scale(1.1, 1.1);
    font-weight: 700;
}

.states-list__toggle-btn {
    display: inline-block;
}

.states-list__header {
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .states-list {
        text-align: center;
        max-width: 300px;
        margin: auto;
    }

    .states-list__toggle-btn {
        display: inline-block;
    }

    .states-list__list {
        display: none;
        columns: 1;
        text-align: left;
    }

    .states-list__list.show {
        display: block;
    }

    .states-list__mobile-list{
        display: block;
        margin: 1rem auto 1.5rem;
        color: #000;
        width: 75vw;
        padding: 0.5rem;
        
        > option {
            color: #000;
        }
    }

    .states-list__header {
        display: none;
    }
}