.page-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vw * 0.4);
    background-color: #000;
    /*background-image: linear-gradient(to right, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.9) 40%, rgba(0,0,0,0) 60%), url(https://ewscripps-brightspot-lower.s3.amazonaws.com/a3/5b/f7d7d50d4769a1d4fcf7ebd83bfd/mystery-page-header-generic.jpg);*/
    background-image: linear-gradient(to right, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.9) 40%, rgba(0,0,0,0) 60%), url(https://ewscripps.brightspotcdn.com/41/93/107df0b14fa1bd7ebe1ea87df933/24-mys-1511168949digital-web-ion-mystery-landing-page-image-3000x2000.jpg);
    background-repeat: no-repeat;
    background-size: auto, cover;
    margin-bottom: 0;
    border-bottom: 4px solid #fff;
}

.page-hero__content {
    color: #fff;
    line-height: 1.4;
}

.page-hero__description {
    width: 66%;
    text-shadow: 1px 1px 1px rgba(0,0,0, 1), 1px 1px 2px rgba(0,0,0, 0.8);
    padding: 1.5rem 1.5rem 1.5rem 0;
    margin-top: -1.5rem;
    font-weight: 500;
}

.main-logo {
    width: 29vw;
    aspect-ratio: 700/294;
    background-image: url(https://ewscripps.brightspotcdn.com/e3/aa/13379eff448895256e2241cb8c5d/logo-ion-mystery-2024-2x.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.mobile-hero-description {
    padding: 1.5rem;
    display: none;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4;
}

@media screen and (max-width: 800px) {
    .page-hero__description {
        display: none;
    }

    .mobile-hero-description {
        display: block;
    }
}
