.ad-anchor {
    display: block;
    position: relative;
    max-width: 768px;
    margin: 0 auto;
    text-decoration: none;     
    cursor: pointer;
    height: 90px;
}
  
.cta-container__link {
    background-image: url("https://cdn.scrippscloud.com/web/images/tablo/ads/JesseFantasyAd_full_768x90.png");
    width: 100%;
    display: block;
    height: inherit;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
  
@media screen and (max-width: 800px) { 
    .ad-anchor {
      max-width: 320px;
      height: 280px;
    }
  
    .cta-container__link {
      background-image: url("https://cdn.scrippscloud.com/web/images/tablo/ads/JesseFantasyAd_full_320x280.png");
    }
}