.find-us {
    position: relative;
}
.find-us__selector {
    display: flex;
}

.usa-map {
    margin: auto;
    flex: 1 1 66%;
}

.states-list {
    flex: 1 1 33%;
}

/* transition styles */
.fade-enter,
.fade-slow-enter {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    opacity: 1;
    transition: all 0.35s ease-out;
}

.fade-slow-enter-active {
    opacity: 1;
    transition: all 1s ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.find-us__ad-wrapper {
    width: 80%;
    margin: 2rem auto;
    padding: 2rem 0 0 0;
    text-align: center;
    border-top: 1px solid var(--primary-color-3);

    img {
        max-width: 100%;
    }
}

.find-us__ad--mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .find-us__selector {
        flex-direction: column;
        justify-content: center;
    }

    .usa-map,
    .states-list {
        flex: 1 1 100%;
    }

    .usa-map {
        margin: unset;
    }
}

@media screen and (max-width: 600px) {
    .find-us__ad-wrapper {
        width: 100%;
    }
    
    .find-us__ad--mobile {
        display: block;
    }

    .find-us__img:not(.find-us__ad--mobile) {
        display: none;
    }
}